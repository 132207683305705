import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            ogpImage
            author
          }
        }
      }
    `
  )

  // Site Data
  const siteTitle = site.siteMetadata.siteTitle
  const siteDescription = site.siteMetadata.siteDescription
  const siteUrl = site.siteMetadata.siteUrl
  const ogpImage = site.siteMetadata.ogpImage
  const siteAuthor = site.siteMetadata.author

  // Page Data
  const lang = 'en'
  const pageTitle = props.pageTitle
  const pageSlug = props.pageSlug
  const pageDescription = props.pageDescription
  const pageImage = props.pageImage

  const pageUrl = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: pageSlug,
      }}
      title={pageTitle ? pageTitle : siteTitle}
      defaultTitle={siteTitle}
    >
      {/* General tags */}
      <meta
        name="image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        name="description"
        content={pageDescription ? pageDescription : siteDescription}
      />

      {/* OpenGraph tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta
        property="og:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta
        property="og:description"
        content={pageDescription ? pageDescription : siteDescription}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteAuthor} />
      <meta name="twitter:title" content={pageTitle ? pageTitle : siteTitle} />
      <meta
        name="twitter:image"
        content={pageImage ? siteUrl + pageImage : siteUrl + ogpImage}
      />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
  )
}

export default SEO
